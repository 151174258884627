import { getItem, postItem, putItem, deleteItem } from '@/api/api-methods';

const url = '/app-personas/boleta';

export const fetchReportCards = async ({
  institutionId = null,
  authorId = null,
  reportCardName = null,
  systemStatus = null,
  period = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const authorFilter = authorId ? `&autor=${authorId}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const searchFilter =
      reportCardName !== null ? `&nombre_boleta=${reportCardName}` : '';
    const periodFilter = period !== null ? `&periodo=${period}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/boleta?'.concat(
        institutionFilter,
        authorFilter,
        systemStatusFilter,
        searchFilter,
        periodFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener las boletas. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener boletas. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener boletas. ' + error,
      count: 0,
    };
  }
};

export const postReportCard = async (reportCardFormData) => {
  try {
    const response = await postItem(url, reportCardFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo crear la boleta. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return { ok: false, message: 'Error al intentar crear boleta. ' + error };
  }
};

export const putReportCard = async (reportCardFormData) => {
  try {
    const id = reportCardFormData.get('id');
    const response = await putItem(`${url}/${id}`, reportCardFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo actualizar la boleta. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar actualizar boleta. ' + error,
    };
  }
};

export const delReportCard = async (id) => {
  try {
    const response = await deleteItem(`${url}/`, id);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo eliminar la boleta. ' + response.e,
      };
    }
    return { ok: true, data: 'Exito al eliminar boleta' };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar eliminar boleta. ' + error,
    };
  }
};

export const assignReportCardToStudent = async (studentId, reportCardIds) => {
  try {
    const response = await postItem(
      '/app-personas/helpers/asignar-boletas-alumno',
      {
        id_alumno: studentId,
        ids_boletas: reportCardIds,
      }
    );
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo asignar boleta(s) al alumno. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar asignar boleta(s) a un alumno. ' + error,
    };
  }
};

export const fetchStudents = async ({
  institutionId = null,
  userId = null,
  credentialNumber = null,
  personalDataId = null,
  tutorId = null,
  tuitionId = null,
  reportCardIds = null,
  systemStatus = null,
  schoolLevel = null,
  schoolLevelId = null,
  is_estimulacion_temprana = null,
  noGroup = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const userIdFilter = userId !== null ? `&usuario=${userId}` : '';
    const credentialNumberFilter =
      credentialNumber !== null
        ? `&numero_credencial_alumno=${credentialNumber}`
        : '';
    const personalDataIdFilter =
      personalDataId !== null ? `&datos_personales=${personalDataId}` : '';
    const tutorIdFilter = tutorId !== null ? `&tutores=${tutorId}` : '';
    const tuitionIdFilter =
      tuitionId !== null ? `&colegiatura=${tuitionId}` : '';
    const reportCardIdsFilter = reportCardIds
      ? `&boletas=${reportCardIds}`
      : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const isEarlyStimulationFilter =
      is_estimulacion_temprana !== null
        ? `&is_estimulacion_temprana=${is_estimulacion_temprana}`
        : '';
    const schoolLevelFilter =
      schoolLevel !== null ? `&nivel_escolar=${schoolLevel}` : '';
    const schoolLevelIdFilter =
      schoolLevelId !== null ? `&nivel_educativo=${schoolLevelId}` : '';
    const noGroupFilter = noGroup !== null ? `&sin_grupo=${noGroup}` : '';

    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/alumno?'.concat(
        institutionFilter,
        userIdFilter,
        credentialNumberFilter,
        personalDataIdFilter,
        tutorIdFilter,
        tuitionIdFilter,
        reportCardIdsFilter,
        systemStatusFilter,
        isEarlyStimulationFilter,
        schoolLevelFilter,
        schoolLevelIdFilter,
        noGroupFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los alumnos. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener alumnos. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener alumnos. ' + error,
      count: 0,
    };
  }
};

export const fetchStudent = async (studentId) => {
  try {
    const response = await getItem(`/app-personas/alumno/${studentId}`);
    const { e, detail } = response;
    if (e || detail) {
      return {
        ok: false,
        message: e ? e : detail ? detail : 'Error al obtener alumno por id',
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar obtener alumno por id. ' + error,
    };
  }
};

export const fetchGroups = async ({
  institutionId = null,
  studentId = null,
  groupName = null,
  branchId = null,
  classroomId = null,
  teacherId = null,
  schoolCycleId = undefined,
  syllabusId = null,
  schoolLevelId = null,
  systemStatus = null,
  isSpecial = null,
  limit = 50,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const studentFilter = studentId ? `&alumnos=${studentId}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const groupNameFilter =
      groupName !== null ? `&nombre_grupo=${groupName}` : '';
    const branchIdFilter = branchId !== null ? `&sucursal=${branchId}` : '';
    const classroomIdFilter =
      classroomId !== null ? `&salon_clases=${classroomId}` : '';
    const teacherIdFilter =
      teacherId !== null ? `&profesores=${teacherId}` : '';
    const schoolCycleIdFilter =
      schoolCycleId !== undefined ? `&ciclo_escolar=${schoolCycleId}` : '';
    const syllabusIdFilter =
      syllabusId !== null ? `&plan_estudios=${syllabusId}` : '';
    const schoolLevelIdFilter =
      schoolLevelId !== null ? `&nivel_educativo=${schoolLevelId}` : '';
    const isSpecialFilter =
      isSpecial !== null ? `&is_especial=${isSpecial}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/grupo?'.concat(
        institutionFilter,
        studentFilter,
        systemStatusFilter,
        groupNameFilter,
        branchIdFilter,
        classroomIdFilter,
        teacherIdFilter,
        schoolCycleIdFilter,
        syllabusIdFilter,
        schoolLevelIdFilter,
        isSpecialFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los grupos. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener grupos. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener grupos. ' + error,
      count: 0,
    };
  }
};
