import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';

import KnMenu from '../../../shared/components/KnMenu.vue';

import { paginationMixin } from '../../../shared/mixins/paginationMixin';
// import { Actions } from '../../../shared/helpers/permissionContants';
// import { can } from '../../../shared/helpers/permissionsUtils';
// import {
//   getSubjectHolder,
//   getTrainingField,
// } from '../../helpers/syllabusOptions';
// import { getFullName } from '../../../shared/helpers/dataUtils';
export default {
  name: 'KnFilteredGroupsTable',
  components: { KnMenu },
  mixins: [paginationMixin],
  props: {
    tableTitle: {
      type: String,
      default: '',
    },
    rememberLastPage: {
      type: Boolean,
      default: false,
    },
    newButtonLabel: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disableBtnCreate: {
      type: Boolean,
      default: false,
    },
    showBottomAction: {
      type: Boolean,
      default: false,
    },
    showInactiveItems: {
      type: Boolean,
      default: false,
    },
    bottomActionText: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    paginationCount: {
      type: Number,
      default: 0,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    disableSearch: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      page: 1,
      search: null,
      selectedItem: null,
      menuOptions: [
        {
          title: 'Ver calificaciones',
          section: [],
          permissions: [],
          active: true,
          disabled: false,
        },
      ],
      showMenu: false,
      x: 0,
      y: 0,
    };
  },
  computed: {
    ...mapState(['currentPage']),
    ...mapGetters(['tutorHasDebts', 'hasAdminPermission']),
    bottomText() {
      return this.bottomActionText
        ? this.bottomActionText
        : `Ver ${this.tableTitle} inactivas`;
    },
    section() {
      if (this.newButtonLabel.includes('calificación')) {
        return 'Calificaciones por grupo';
      }
      return '';
    },
    routeName() {
      if (this.newButtonLabel.includes('calificación')) {
        return 'Calificaciones';
      }
      return '';
    },
    currentItems() {
      // console.log('Items recibidos', this.items);
      return this.items;
    },
  },
  watch: {
    paginationCount: function () {
      if (this.rememberLastPage) {
        this.page = this.currentPage || 1;
        this.setCurrentPage(this.currentPage);
      } else {
        this.page = 1;
      }
    },
  },
  methods: {
    ...mapMutations(['setSearch', 'setSelectedId', 'setCurrentPage']),
    ...mapActions(['clearSearch']),
    goTo(routeName, params) {
      return this.$router.push({ name: routeName, params: params });
    },
    emitAction(value) {
      this.$emit('action', value);
    },
    emitAction2(value) {
      this.$emit('action2', value);
    },
    emitShowAction() {
      this.clearSearch();
      this.$emit('showAction');
    },
    emitActionResults(value) {
      this.$emit('resultsAction', value);
    },
    emitChangePage() {
      this.$emit('onChangePage', this.page);
    },
    emitSearch() {
      // console.log('Click en lupa');
      this.$emit('onSearch');
    },
    emitClearSearch() {
      this.clearSearch();
      this.$emit('onClearSearch');
    },
    async fetchData() {},
    openMenu(item, event) {
      // console.log('dentro de openMenu', item);
      // console.log('event x', event.clientX);
      // console.log('event y', event.clientY);
      this.x = event.clientX;
      this.y = event.clientY;
      this.showMenu = true;
      this.selectedItem = item;
    },
    closeMenu() {
      this.showMenu = false;
      this.selectedItem = null;
    },
  },
};
